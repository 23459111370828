import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'

interface SeeMoreProps {
	text?: string;
	limit?: number;
	textColor?:string | null | undefined;
	// don't wrap content in Typography
	noWrap?: boolean;
}

export default function SeeMore({textColor="", text="", noWrap, limit=250}: SeeMoreProps){
	const [display, setDisplay] = useState("");
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		if(text && text.length > 0){
		setDisplay(text.substr(0, limit))
		}
	}, [text])

	const toggle = () => {
		if(text && text.length > 0){
			if(expanded){
				setDisplay(text.substr(0, limit))
			} else{
				setDisplay(text)
			}
			setExpanded(e => !e)
		}	
	}
	const content = <>
			{display} 
			{text?.length>limit && <b style={{marginLeft: '1ch', cursor:'pointer'}} onClick={toggle}>{expanded?'hide.':'See More...'}</b>}
		</>
	if(noWrap) return content
	if(textColor=="" || textColor==undefined || textColor==null){
	return <Typography color="textSecondary" style={{wordBreak: 'break-word', overflowWrap: 'break-word'}}>
		{content}
	</Typography>
	}
	if(textColor!=="" || textColor!==undefined || textColor!==null){
		return <Typography style={{wordBreak: 'break-word', overflowWrap: 'break-word',  color:"rgba(0, 0, 0, 0.87)", fontSize:"14px"}}>
		{content}
	</Typography>
	}
	return <Typography color="textSecondary" style={{wordBreak: 'break-word', overflowWrap: 'break-word'}}>
		{content}
	</Typography>
	
}